import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { RideHistoryService } from './ride-history.service';
import { catchError, mergeMap, take } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AuthService } from '../../security/service/auth.service';
import { ProfileInfoResponseType } from '../domain/profile';

@Injectable({
    providedIn: 'root',
})
export class RideHistoryResolverService implements Resolve<ProfileInfoResponseType> {
    constructor(
        private readonly rideHistoryService: RideHistoryService,
        private readonly authService: AuthService,
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): ProfileInfoResponseType | Observable<ProfileInfoResponseType> | Promise<ProfileInfoResponseType> {
        return this.rideHistoryService.getProfileInfo().pipe(
            take(1),
            mergeMap((profileInfo) => {
                return of(profileInfo?.payment_link?.card 
                    ? [profileInfo?.payment_link?.card] 
                    : []);
            }),
            catchError((error) => {
                if (error.status === 401) {
                    return;
                }
                const message = `Retrieval error: ${error}`;
                this.authService.dataStream.next(1);
                return null;
            }),
        );
    }
}
