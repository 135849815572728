import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {LocalizationService} from 'src/app/localization/service/localization.service';
import {CreateIssueRequest} from 'src/app/ride-history/domain/create-issue-request';
import {PassesListPart} from 'src/app/ride-history/domain/passes-list-part';
import {
    OnboardingLinkResponseType,
    PaymentLinkRegisterResponseType,
    PaymentStatusResponseType,
    ProfileInfoResponseType
} from 'src/app/ride-history/domain/profile';
import { catchError } from 'rxjs/operators';
import {PsListRequest} from 'src/app/ride-history/domain/ps-list-request';
import { HandleError, HttpErrorHandler } from '../../http/http-error-handler.service';
import { Observable } from 'rxjs';
import { PayDebtCardResponseType } from '../domain/pay-type';
import { ReceiptByIdResponseType } from '../domain/pass';

@Injectable({
    providedIn: 'root',
})
export class RideHistoryService {
    private readonly handleError: HandleError;

    constructor(
        private readonly http: HttpClient,
        private readonly httpErrorHandler: HttpErrorHandler,
        private readonly localizationService: LocalizationService,
    ) {
        this.handleError = httpErrorHandler.createHandleError('RideHistoryService');
    }

    public getProfileInfo(): Observable<ProfileInfoResponseType> {
        return this.http
            .get<ProfileInfoResponseType>('/profile/info')
            .pipe(catchError(this.handleError('getProfileInfo', null)));
    }

    public revokeConsent(): Observable<any> {
        return this.http
            .patch<any>('/profile?status=CONSENT_REVOKED', null)
            .pipe(catchError(this.handleError('revokeConsent', null)));
    }

    public getOnboardingLink(): Observable<OnboardingLinkResponseType> {
        return this.http
            .get<OnboardingLinkResponseType>('/profile/onboarding_link')
            .pipe(catchError(this.handleError('getOnboardingLink', null)));
    }

    public paymentLinkRegister(): Observable<PaymentLinkRegisterResponseType> {
        return this.http
            .post<PaymentLinkRegisterResponseType>(
                `/profile/payment_links/register`,
                {
                    payment_type: 'BANK_CARD'
                },
                {
                    headers: {
                        session_id: localStorage.getItem('token')
                    }
                }
            )
            .pipe(catchError(this.handleError('paymentLinkRegister', null)));
    }

    public checkPaymentStatus(paymentLinkId: string): Observable<PaymentStatusResponseType> {
        return this.http
            .get<PaymentStatusResponseType>(`/profile/payment_links/${paymentLinkId}/status`)
            .pipe(catchError(this.handleError('addBankCard', null)));
    }

    // TODO: ТИПИЗИРОВАТЬ ТУТ ВСЕ
    public startToLinkCard(): Observable<any> {
        const language = this.localizationService.currentLanguage.toUpperCase();
        return this.http
            .get<any>(`/tp/link/create?language =${language}`, {})
            .pipe(catchError(this.handleError('startToLinkCard', [])));
    }

    public getLinkCardStatus(id: string): Observable<any> {
        return this.http.post('/tp/link/status', { id }).pipe(catchError(this.handleError('getLinkCardStatus', [])));
    }

    public deleteCard(id: string): Observable<any> {
        return this.http.delete('/tp/link/delete/' + id).pipe(catchError(this.handleError('deleteCard', [])));
    }

    public makeCardFavorite(id: string): Observable<any> {
        return this.http
            .put('/tp/cards/make/favourite/' + id, {})
            .pipe(catchError(this.handleError('makeCardFavorite', [])));
    }

    public getPassesListPart({card_id, ...params}: PsListRequest): Observable<PassesListPart> {
        return this.http
            .get<any | PassesListPart>(`/card/${card_id}/passes`, {params: params as any})
            .pipe(catchError(this.handleError('getPassesListPart', [])));
    }

    public createIssue(createIssueRequest: CreateIssueRequest): Observable<any> {
        return this.http
            .post<any>('/tp/issues/create', createIssueRequest)
            .pipe(catchError(this.handleError('createIssue', [])));
    }

    public getLinkedPassByTravelId(payload): Observable<any> {
        return this.http
            .post<any>('/tp/card/pass/travel', payload)
            .pipe(catchError(this.handleError('getLinkedPassByTravelId', [])));
    }

    public getReceiptById(pass_id): Observable<ReceiptByIdResponseType> {
        return this.http
            .get<ReceiptByIdResponseType>(`/card/pass/${pass_id}/receipt`)
            .pipe(catchError(this.handleError('getReceiptById', null)));
    }

    public payDebtCard(card_id: string): Observable<PayDebtCardResponseType> {
        return this.http
            .post<PayDebtCardResponseType>(`/card/${card_id}/repayment`, {})
            .pipe(catchError(this.handleError('payDebtCard', null)));
    }
}
