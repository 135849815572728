import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    private sessionExpiredSubject = new Subject<void>();

    sessionExpired$ = this.sessionExpiredSubject.asObservable();

    triggerSessionExpired() {
        this.sessionExpiredSubject.next();
    }
}